<template>
    <uw-content title="考核指标" padding="10px">

        <!-- 菜单 -->
        <template slot="suffix">
            <el-button type="primary" size="mini" @click="$refs.dialogCreate.Open()">新建指标</el-button>
        </template>

        <!-- 表格 -->
        <uw-table>
            
            <!-- 检索 -->
            <template slot="search">
                <hrm-kpi-goals-type edit v-model="search.type" @onChange="TableSearch" />
                <users-select multiple v-model="search.users_id"  @onChange="TableSearch" />
                <el-date-picker v-model="search.year" @change="TableSearch" type="year" value-format="yyyy" size="mini" placeholder="考核年度" />
            </template>

            <!-- 按钮 -->
            <template slot="button">
                <el-button type="primary" size="mini" @click="$refs.xTable.print()" >打印</el-button>
                <el-button type="primary" size="mini" @click="$refs.xTable.openExport()" >导出</el-button>
            </template>

            <!-- 表数据 -->
            <template slot="data">
                <!-- 表内容 -->
                <vxe-table
                    ref="xTable"
                    :data="table"
                    :loading="loading"
                    :export-config="{}"
                    :print-config="{}"
                    :seq-config="{startIndex: page.size * (page.current - 1)}"
                    height="100%">

                    <!-- 考核年度 -->
                    <vxe-column min-width="60" field="year" title="年度" align="center"></vxe-column>

                    <!-- 联系人 -->
                    <vxe-column min-width="120" title="联系人">
                        <template #default="{ row }">
                            <div style="display: flex; align-items: center; justify-content: flex-start;">
                                <el-avatar style="margin-right: 5px;" :size="26" :src="row.users.avatar"> {{ row.users.name[0] }} </el-avatar>
                                {{  row.users.name }}
                            </div>
                        </template>
                    </vxe-column>

                    <!-- 考核指标 -->
                    <vxe-column min-width="120" title="考核指标">
                        <template #default="{ row }">
                            <hrm-kpi-goals-type v-model="row.type" />
                        </template>
                    </vxe-column>

                    <!-- 考核目标 -->
                    <vxe-column min-width="80" field="target_sum" title="全年目标" ></vxe-column>
                    <vxe-column min-width="80" field="target_q1" title="第一季度" ></vxe-column>
                    <vxe-column min-width="80" field="target_m1" title="1月" ></vxe-column>
                    <vxe-column min-width="80" field="target_m2" title="2月" ></vxe-column>
                    <vxe-column min-width="80" field="target_m3" title="3月" ></vxe-column>
                    <vxe-column min-width="80" field="target_q2" title="第二季度" ></vxe-column>
                    <vxe-column min-width="80" field="target_m4" title="4月" ></vxe-column>
                    <vxe-column min-width="80" field="target_m5" title="5月" ></vxe-column>
                    <vxe-column min-width="80" field="target_m6" title="6月" ></vxe-column>
                    <vxe-column min-width="80" field="target_q3" title="第三季度" ></vxe-column>
                    <vxe-column min-width="80" field="target_m7" title="7月" ></vxe-column>
                    <vxe-column min-width="80" field="target_m8" title="8月" ></vxe-column>
                    <vxe-column min-width="80" field="target_m9" title="9月" ></vxe-column>
                    <vxe-column min-width="80" field="target_q4" title="第四季度" ></vxe-column>
                    <vxe-column min-width="80" field="target_m10" title="10月" ></vxe-column>
                    <vxe-column min-width="80" field="target_m11" title="11月" ></vxe-column>
                    <vxe-column min-width="80" field="target_m12" title="12月" ></vxe-column>

                    <!-- 操作 -->
                    <vxe-column width="100"  title="操作" align="center" fixed="right">
                        <template #default="{ row }">
                            <el-link @click.native.stop="$refs.dialogUpdate.Open(row.id)" type="primary" class="w-margin-r-10">修改</el-link>
                            <el-link @click.native.stop="TableDelete(row.id)" type="danger">移除</el-link>
                        </template>
                    </vxe-column>

                </vxe-table>
            </template>

            <!-- 分页 -->
            <template slot="pager">
                <vxe-pager :total="page.total" :page-size.sync="page.size" :current-page.sync="page.current" @page-change="TableSearch()"></vxe-pager>
            </template>
            
        </uw-table>

        <!-- 组件 -->
        <hrm-kpi-goals-create ref="dialogCreate" @onChange="TableSearch()" />
        <hrm-kpi-goals-update ref="dialogUpdate" @onChange="TableSearch()" />
        
    </uw-content>
</template>

<script>
export default {
    data () {
        return {
            // 数据
            table: [],

            // 分页
            page: {
                total: 0,
                size: 50,
                current: 1,
            },

            // 检索
            search: {
                type: null,
                users_id: null
            },

            // 排序
            order: {
                method: 'desc',
                column: 'id'
            },
        }
    },

    computed: {
        loading () {
            return !!this.$store.state.httpRequestCount
        }
    },

    mounted () {
        this.TableSearch()
    },

    methods: {

        // 查询
        TableSearch()
        {
            this.$http.post(
                '/9api/hrm/kpi-goals/search',
                {
                    page: this.page,
                    search: this.search,
                    order: this.order,
                    append: ['users']
                }
            ).then((rps) => {
                // 更新数据
                this.page.total = rps.data.total
                this.table = rps.data.body

                // 页码溢出
                if (this.table.length == 0 && this.page.current != 1) {
                    this.page.current = 1
                    this.TableSearch()
                }
            })
        },

        // 移除
        TableDelete(id)
        {
            this.$http.post('/9api/hrm/kpi-goals/delete', { id: id }).then(rps => {
                this.TableSearch()
            })
        }
    }
}
</script>